import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { PageHeader } from '../components/PageHeader'
import ProductList from '../components/Posts/ProductList'
import { decodeEntities } from '../utils/helpers'
import { LinkTiles } from '../components/Acf/LinkTiles'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export default class ProductIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const {
      wordpressPost: page,
      allWordpressWpProducts,
      categoryFilter,
      categories,
      wordpressWpSettings,
      siteSettings,
    } = data
    const {
      title,
      yoast,
      yoast_head,
      acf,
      childWordPressAcfLinkTiles,
    } = page || {
      title: null,
      yoast: {},
      acf: {},
      childWordPressAcfLinkTiles: null,
    }

    const { title: siteTitle } = wordpressWpSettings
    const { edges: posts } = allWordpressWpProducts
    const isParent =
      location.pathname.split('/').length <= 3 &&
      location.pathname !== '/products/' // Check url for depth
    const { newsletterText } = siteSettings.options

    return (
      <>
        <SEO
          title={`${
            yoast.metaTitle
              ? yoast.metaTitle
              : `Products | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
          location={location}
        />
        {childWordPressAcfLinkTiles && (
          <LinkTiles {...childWordPressAcfLinkTiles} />
        )}
        <PageHeader headerTitle="Products" location={location} />
        <div className="quick-add-modal">
          <div className="inner" />
        </div>
        <ProductList
          posts={posts}
          title="Latest products"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix="/products/"
          isParent={isParent}
        />
        <NewsletterSignUp content={newsletterText} />
      </>
    )
  }
}

ProductIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProducts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProductIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressPage(slug: { eq: "products" }) {
      title
      wordpress_id
      # yoast_head
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
      childWordPressAcfLinkTiles {
        title
        introContent
        tiles {
          link {
            target
            title
            url
          }
          content
          image {
            localFile {
              childImageSharp {
                fixed(width: 540) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          icon {
            source_url
            localFile {
              publicURL
            }
          }
        }
      }
    }
    categoryFilter: allWordpressWpProducts {
      nodes {
        product_category
      }
    }
    allWordpressWpProducts(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    categories: allWordpressWpProductCategory(
      filter: { slug: { ne: "uncategorised" } }
    ) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
